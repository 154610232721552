
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      remainingTime: 5
    }
  },
  mounted() {
    const interval = setInterval(() => {
      this.remainingTime--
      if (this.remainingTime === 0) {
        clearInterval(interval)
        this.$router.push('/')
      }
    }, 1000);
  }
})
